// project import
import meeting from './meeting';
import setting from './setting';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [meeting, setting]
};

export default menuItems;
